<template>
    <div class="bg-f">
        <!--公共头部-->
        <Header></Header>
        <!-- 用户中心头部 -->
        <user-header />
        <!-- 内容 -->
        <div class="main-content">
            <div class="container">
                <div class="accout-content clearfix"  style="display:flex">
                    <!-- 左边 -->
                    <user-left mark="event" :userId="userId" />
                    <!-- 右边 -->
                    <a-card :bordered="false" class="card-right" style="width:100%">
                        <div style="font-size:18px;font-weight:bold;margin-bottom:20px">{{ $t('user.base-info') }}</div>
                        <div class="first">
                            <span>{{ $t('user.inquiry-title') }}:</span>
                            <span>{{ list.subject }}</span>
                        </div>
                        <div class="second">
                            <span>{{ $t('user.buy-number') }}:</span>
                            <span>{{ list.purchaseQuantity }}</span>
                        </div>
                        <div class="second">
                            <span>{{ $t('user.contact-info') }}:</span>
                            <span>{{ list.concatInfo }}</span>
                        </div>
                        <div style="font-size:18px;font-weight:bold;margin-top:20px">{{ $t('user.purchaser.info') }}</div>
                        <div class="second">
                            <span>{{ $t('user.company-name') }}:</span>
                            <span>{{ list.purchaser.companyName }}</span>
                        </div>
                        <div class="second">
                            <span>{{ $t('user.company-area') }}:</span>
                            <span>{{ list.purchaser.countryName }}</span>
                        </div>
                    </a-card>
                </div>
            </div>
        </div>
        <!-- 公共尾部 -->
        <Footer></Footer>
    </div>
</template>

<style>
    .main-content .card-right{
        width: 100%;
    }
    .first,.second{
        text-align: left;
    }
    .first>span,.second>span{
        font-size: 16px;
        width: 140px;
        display: inline-block;
    }
    .second>span:nth-child(2){
        width: 600px;
    }
    input[type=text]{
      width: 300px;
      border: 1px solid #787878;
      margin-left: 10px;
    }
    .second{
        margin-top: 20px;
    }
    .submit{
        width: 200px;
        height: 40px;
        margin-top: 40px;
        border: 1px solid  #d9d9d9;
        line-height: 40px;
        background: #ff4d4f;
        color: #fff;
        font-size: 16px;
        border-radius: 4px;
    }
    .ant-select-selection--single{
        margin-left: 10px;
        border: 1px solid black;
    }
    .ant-upload.ant-upload-select-picture-card{
        margin-left: 10px;
    }
    .ant-calendar-picker{
        margin-left: 10px;
    }
    .queot{
        text-align: left;
        font-size: 16px;
        margin-top: 10px;
    }
    .ant-btn-primary{
        margin-left: 20px;
    }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  name: 'ExbitAdd',
  inject: ['reload'], // 注入依赖
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
        userId: this.$route.params.userId,
        id: this.$route.params.id,
        list: {
            purchaser: {},
        },
    }
  },
   mounted () {
    this.getList()
  },
   methods: {
     //获取rqf详情
    getList () {
       this.$common.fetchList('/user/purchase/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>